import { FirebaseFirestore, MarchandType } from '@innedit/innedit';
import React, { FC, useEffect, useState } from 'react';

import IconEye from '../../../../../../icons/Eye';
import IconEyeSlash from '../../../../../../icons/EyeSlash';
import Item from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const ListItem: FC<ListItemProps<MarchandType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<MarchandType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const data = doc.data() as MarchandType;

  if (!data) {
    return null;
  }

  return (
    <Item doc={doc} index={index} onClick={onClick}>
      <div className="ml-3 flex items-center space-x-3">
        {!data.hidden && <IconEye />}
        {data.hidden && <IconEyeSlash />}
        <div>
          <strong className="name">{data.libelle}</strong>
          {data.stripeAccountId && (
            <span className="block">Compte connecté</span>
          )}
        </div>
      </div>
    </Item>
  );
};

export default ListItem;
